import * as React from 'react';
import {
	createElement,
	ReactNode,
	useState,
} from 'react';
import {
	Tooltip as ReactTooltip,
	ITooltip,
} from 'react-tooltip';

// STYLES
import styles from './tooltip.module.scss';
import 'react-tooltip/dist/react-tooltip.css';

enum TooltipPosition {
	BOTTOM = 'bottom',
	LEFT = 'left',
	RIGHT = 'right',
	TOP = 'top',
	TOP_START = 'top-start',
	TOP_END = 'top-end',
	RIGHT_START = 'right-start',
	RIGHT_END = 'right-end',
	BOTTOM_START = 'bottom-start',
	BOTTOM_END = 'bottom-end',
	LEFT_START = 'left-start',
	LEFT_END = 'left-end',
}

interface TooltipProps extends ITooltip {
	className?: string;
	children: ReactNode;
	'data-testid'?: string;
	disabled?: boolean;
	place?: TooltipPosition;
	htmlElement?: ReactNode;
	triggerAs?: keyof JSX.IntrinsicElements; // New prop to define the type of trigger element
}

const Tooltip = ({
	children,
	content,
	className,
	'data-testid': dataTestid,
	delayHide = 0,
	delayShow = 500,
	disabled = false,
	id,
	place,
	htmlElement,
	triggerAs = 'div',
}: TooltipProps): JSX.Element => {

	const cssClasses = [
		styles.tooltip,
	];
	const [
		isOpen,
		setIsOpen
	] = useState(false);

	if (className) cssClasses.push(className);

	const handleMouseEnter = () => {
		if (!disabled) setIsOpen(true);
	};

	const handleMouseLeave = () => {
		setIsOpen(false);
	};

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
		>
			{createElement(
				triggerAs,
				{
					'data-testid': `${dataTestid}-trigger`,
					'data-tooltip-content': content,
					'data-tooltip-id': id,
					onMouseEnter: handleMouseEnter,
					onMouseLeave: handleMouseLeave,
				},
				children
			)}
			<ReactTooltip
				className={styles.customTooltip}
				delayHide={delayHide}
				delayShow={delayShow}
				float={false}
				id={id}
				isOpen={isOpen}
				offset={10}
				place={place}
			>
				{htmlElement}
			</ReactTooltip>
		</div>
	);
};

export {
	Tooltip as default,
	TooltipProps,
	TooltipPosition,
};
