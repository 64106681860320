import * as React from 'react';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// COMPONENTS
import Icon from '@components/icon';

//STYLES
import styles from './image.module.scss';
import {
	EnumImageFormat, 
} from '@enums/image.enum';

type imagePlaceholderProps = {
	icon?: string;
	className?: string;
}

type stylePropertiesProps = {
	height?: string;
	minHeight?: string;
	minWidth?: string;
	width?: string;
}

interface ImageProps {
	'aria-hidden'?: string;
	alt?: string;
	className?: string;
	'data-testid'?: string;
	format?: EnumImageFormat;
	height?: string;
	imagePlaceholder?: imagePlaceholderProps;
	minHeight?: string;
	minWidth?: string;
	rounded?: boolean;
	src?: string;
	srcSetValues?: Array<string>;
	srcset?: Array<string>;
	styleProperties?: stylePropertiesProps;
	width?: string;
}

const Image = ({
	'aria-hidden': ariaHidden,
	alt,
	className,
	'data-testid': dataTestid,
	format,
	height,
	imagePlaceholder = {
		icon: 'picture',
	},
	minHeight,
	minWidth,
	rounded = false,
	src,
	srcset,
	width,
}: ImageProps): JSX.Element => {

	const { t } = useTranslation();

	const [
		localState,
		setLocalState
	] = useState({
		isLoadable: src || srcset ? true : false
	});

	let altTxt = alt || undefined;

	const styleProperties: ImageProps = {
	};
	if (height) {
		styleProperties.height = height;
	}
	if (minHeight) {
		styleProperties.minHeight = minHeight;
	}
	if (minWidth) {
		styleProperties.minWidth = minWidth;
	}
	if (width) {
		styleProperties.width = width;
	}

	let cssClasses = [
		styles.image
	];

	if (className) {
		cssClasses.push(...className.split(' '));
	}

	if (rounded) {
		cssClasses.push(styles.rounded);
	}

	if (format && styles[`format__${format}`]) {
		cssClasses.push(styles[`format__${format}`]);
	}

	const cssClassesPlaceholder = [
		styles.placeholder
	];

	// since we mock the image loading with Jest so we won't test it
	/* istanbul ignore next */
	const placeholderElement = localState.isLoadable ? null : (
		<div
			className={cssClassesPlaceholder.join(' ')}
			data-testid={`${dataTestid}-placeholder`}
		>
			<Icon name={imagePlaceholder ? imagePlaceholder?.icon : undefined} />
		</div>
	);

	cssClasses.push(styles.image__with_placeholder);

	// since we mock the image loading with Jest so we won't test it
	/* istanbul ignore next */
	if (localState.isLoadable) {
		cssClasses = cssClasses.filter(cssClasse => cssClasse !== styles.image__with_placeholder);
		altTxt = t('component.image.unavailable');
	}
	if (imagePlaceholder?.className) cssClassesPlaceholder.push(imagePlaceholder.className);

	// srcset management
	const srcSetValues = [
	];

	if (srcset && srcset.length) {
		const breakpointsValues = Object.values(APP_CONF_VARS.breakpoints);
		for (let index = 0; index < srcset.length; index++) {
			const breakPointKey = breakpointsValues[index + 2];
			srcSetValues.push(`${srcset[index]} ${breakPointKey}w`);
		}
	}

	// since we mock the image loading with Jest so we won't test it
	/* istanbul ignore next */
	const handleOnError = () => {
		setLocalState({
			...localState,
			isLoadable: false // only loadable change if placeholder has been defined
		});
	};

	if (!ariaHidden) {
		// since we mock the image loading with Jest so we won't test it
		/* istanbul ignore next */
		ariaHidden = !localState.isLoadable || !alt ? 'true' : 'false';
	}

	// since we mock the image loading with Jest so we won't test it
	/* istanbul ignore next */
	const displayProp = localState.isLoadable ? 'block' : 'none';

	const imgTag = src || srcset ? (
		<img
			alt={altTxt}
			aria-hidden={ariaHidden}
			data-testid={`${dataTestid}-img`}
			src={src}
			srcSet={srcSetValues.length ? srcSetValues.join(', ') : undefined}
			style={{
				display: displayProp
			}}
			onError={handleOnError}
		/>
	) : null;

	return (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			style={styleProperties}
		>
			{imgTag}
			{placeholderElement}
		</div>
	);
};

export {
	Image as default,
	ImageProps,
};
