export enum MandateType {
	SIMPLE = 'simple',
	EXCLUSIVE = 'exclusive',
	REGULATION = 'regulation',
}

export interface MandateJson {
	type?: MandateType;
	is_owner?: boolean;
}

export class Mandate {
	public mandate: MandateJson;

	constructor(
		mandate: MandateJson
	) {
		this.mandate = mandate;
	}

	get is_owner(): boolean {
		return this.mandate.is_owner || false;
	}

	get type(): MandateType | undefined {
		return this.mandate.type;
	}
}
