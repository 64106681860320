import {
	APP_CONF_VARS,
	InterfaceHostsConf,
} from '@appConf/vars.conf';
import {
	ObjectKeyStringValueString,
} from '@@types/Settings';
import {
	EnumEnvironments,
} from '@appConf/vars.conf';

export interface Utils {
	getURL(
		path: string,
		args?: ObjectKeyStringValueString): string;
	getEnvFromhost(): EnumEnvironments;
	getQueryParams(url: string): string[];
}

const utils: Utils = {
	getURL(path, args) {
		let result = `${path}`;

		if (args && Object.keys(args).length) {
			Object.keys(args).forEach(argKey => {
				result = result.replace(argKey, args[argKey]);
			});
		}
		return result;
	},

	getQueryParams(inputString: string = ''): string[] {
		let queryString = '';
		let queryParams: string[] = [
		];
		if (inputString.includes('?')) {
			queryString = inputString.split('?')[1];
		}
		if (queryString.length && queryString.includes('=')) {
			queryParams = queryParams.concat(queryString.split('&'));
		}

		return queryParams;
	},

	getEnvFromhost(): EnumEnvironments {
		let result: EnumEnvironments = EnumEnvironments.PRODUCTION;
		const hostsList: InterfaceHostsConf = APP_CONF_VARS.hosts;
		let envResults: EnumEnvironments[] = [
		];
		Object.keys(hostsList).forEach(contextName => {
			const context = Object.keys(hostsList[contextName]) as EnumEnvironments[];
			const contextResult: EnumEnvironments[] = context.filter((envKey: EnumEnvironments) => {
				return hostsList[contextName][envKey].includes(window.location.host);
			});
			envResults = [
				...envResults,
				...contextResult
			];
		});

		if (envResults.length) result = envResults[0];
		return result;
	}
};

export default utils;
