import React from 'react';
import {
	withTranslation,
	useTranslation,
} from 'react-i18next';

// COMPOENENTS
import Feature from '@components/feature';
import Paragraph from '@components/paragraph';
import Tooltip, {
	TooltipPosition,
} from '@components/tooltip';

// STYLES
import styles from './SearchCriterias.module.scss';

// TYPES
import {
	SearchCriterias,
} from '@@types/Demand';

interface SearchCriteriasProps {
	className?: string;
	company?: {
		name?: string;
	};
	data?: SearchCriterias;
	editUrl?: string;
}

function SearchCriterias({
	className,
	company,
	data,
	editUrl,
}: SearchCriteriasProps) {
	const { t } = useTranslation();
	const cssClasses = [
		styles.container
	];

	if (className) cssClasses.push(className);

	let displayedDemandTypes = null;
	if (data?.demand_types?.length === 1) {
		displayedDemandTypes = `${t(`general.${data?.demand_types[0]}`)}`;
	} else if (data?.demand_types?.length === 2) {
		displayedDemandTypes = `${t(`general.${data?.demand_types[0]}`)} ${t('general.and')} ${t(`general.${data?.demand_types[1]}`)}`;
	}

	const displayedNatures = data?.natures?.length ? (
		data?.natures.map((nature: string) => {
			return t(`references.natures.${nature}`);
		}).join(' - ')
	) : null;

	const natureContent = t('format.capitalize', {
		text: `${displayedDemandTypes} : ${displayedNatures}`
	});

	const naturesElement = displayedNatures ? (
		<Feature
			className={`${styles.natures} ${styles.child}`}
			icon='key'
		>
			<Tooltip
				content={natureContent}
				id='nature'
				place={TooltipPosition.BOTTOM}
			>
				{natureContent}
			</Tooltip>
		</Feature>
	) : null;

	const locationsContent = data?.locations?.join(' - ');

	const locationsElement = data?.locations?.length ? (
		<Feature
			className={`${styles.locations} ${styles.child}`}
			icon='map-marker-alt'
		>
			<Tooltip
				content={locationsContent}
				id='locations'
				place={TooltipPosition.BOTTOM}
			>
				{locationsContent}
			</Tooltip>
		</Feature>
	) : null;

	let areaDiplayed = null;
	if (data?.area?.min?.value && data?.area?.max?.value) {
		areaDiplayed = t('format.to', {
			from: t('format.number', {
				val: data.area.min.value
			}),
			to: t('format.area', {
				val: data.area.max.value
			})
		});
	} else if (data?.area?.max?.value) {
		areaDiplayed = t('format.max', {
			text: t('format.area', {
				val: data.area.max.value
			})
		});
	} else if (data?.area?.min?.value) {
		areaDiplayed = t('format.min', {
			text: t('format.area', {
				val: data.area.min.value
			})
		});
	}

	const areaElement = areaDiplayed ? (
		<Feature
			className={styles.child}
			icon='area'
		>
			<Tooltip
				content={areaDiplayed}
				id='area'
				place={TooltipPosition.BOTTOM}
			>
				{areaDiplayed}
			</Tooltip>
		</Feature>
	) : null;

	let budgetDisplayed = null;
	if ((data?.budget?.max?.amount || data?.budget?.min?.amount) && data?.demand_types.length === 1) {
		if (data?.demand_types[0] === 'rent') {
			if (data?.budget?.max?.amount) {
				budgetDisplayed = t('format.max', {
					text: t('format.per.yearly', {
						text: t('format.price', {
							val: data?.budget?.max?.amount
						})
					})
				});
			} else if (data?.budget?.min?.amount) {
				budgetDisplayed = t('format.min', {
					text: t('format.per.yearly', {
						text: t('format.price', {
							val: data?.budget?.min?.amount
						})
					})
				});
			}
		} else {
			if (data?.budget?.max?.amount) {
				budgetDisplayed = t('format.max', {
					text: t('format.price', {
						val: data?.budget?.max?.amount
					})
				});
			} else if (data?.budget?.min?.amount) {
				budgetDisplayed = t('format.min', {
					text: t('format.price', {
						val: data?.budget?.min?.amount
					})
				});
			}
		}
	}

	let budgetM2Displayed = null;
	if ((data?.budget_m2?.max?.amount || data?.budget_m2?.min?.amount) && data?.demand_types.length === 1) {
		if (data?.demand_types[0] === 'rent') {
			if (data?.budget_m2?.max?.amount) {
				budgetM2Displayed = t('format.max', {
					text: t('format.per.yearly', {
						text: t('format.per.square_meter', {
							text: t('format.price', {
								val: data?.budget_m2?.max?.amount
							})
						})
					})
				});
			} else if (data?.budget_m2?.min?.amount) {
				budgetM2Displayed = t('format.min', {
					text: t('format.per.yearly', {
						text: t('format.per.square_meter', {
							text: t('format.price', {
								val: data?.budget_m2?.min?.amount
							})
						})
					})
				});
			}
		} else {
			if (data?.budget_m2?.max?.amount) {
				budgetM2Displayed = t('format.max', {
					text: t('format.per.square_meter', {
						text: t('format.price', {
							val: data?.budget_m2?.max?.amount
						})
					})
				});
			} else if (data?.budget_m2?.min?.amount) {
				budgetM2Displayed = t('format.min', {
					text: t('format.per.square_meter', {
						text: t('format.price', {
							val: data?.budget_m2?.min?.amount
						})
					})
				});
			}
		}
	}

	const budgetElement = budgetDisplayed || budgetM2Displayed ? (
		<Feature
			className={styles.child}
			icon='euro-sign'
		>
			<Tooltip
				content={budgetDisplayed || budgetM2Displayed}
				id='budget'
				place={TooltipPosition.BOTTOM}
			>
				{budgetDisplayed || budgetM2Displayed}
			</Tooltip>
		</Feature>
	) : null;

	const searchCriteriasElement = naturesElement || locationsElement || areaElement || budgetElement ? (
		<div className={styles.search_criterias}>
			<div className={styles.element}>
				{naturesElement}
				{locationsElement}
				{areaElement}
				{budgetElement}
			</div>
		</div>
	) : null;

	return (
		<Paragraph
			className={cssClasses.join(' ')}
			editUrl={editUrl ? editUrl : null}
			hasBorder={true}
		>
			<div className={styles.title}>
				{t('format.capitalize', {
					text: t('page.demand.public.specifications', {
						'%companyName%': company?.name
					})
				})}
			</div>
			{searchCriteriasElement}
		</Paragraph>
	);
}

export default withTranslation()(SearchCriterias);
