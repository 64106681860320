import * as React from 'react';

import {
	ChangeEvent,
	ReactNode,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumInputType,
	EnumInputTheme,
	EnumFormMode,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';

// COMPONENTS
import Checkbox from '@components/checkbox';
import Input from '@components/form/input';
import LabelInput from '@components/form/input-label';
import Toggle from '@components/toggle';
import {
	InputProps,
} from '@components/form/input';

// STYLES
import styles from './input-checkbox.module.scss';

interface InputCheckboxProps extends Omit<InputProps, 'hasBorder' | 'hasShadow' | 'label'> {
    theme?: EnumInputTheme;
    toggleIcon?: boolean;
	label?: string | ReactNode;
}

const InputCheckbox = ({
	checked = false,
	className,
	customError,
	'data-testid': dataTestid,
	disabled = false,
	id,
	innerRef,
	invalid,
	dirty,
	label,
	methods,
	name,
	onChange,
	required = false,
	tabIndex = 0,
	theme = EnumInputTheme.CHECKBOX,
	toggleIcon = false,
	validationType,
	...otherProps
}: InputCheckboxProps): JSX.Element => {
	const { t } = useTranslation();
	const isControled = methods?.register;
	const registerRules: InputProps['registerRules'] = {
	};

	/* istanbul ignore next */
	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: t('general.form.input.error.checkbox')
			};
		}
	}

	const initialState: InputCheckboxProps = {
		...otherProps,
		checked: checked,
		customError: customError,
		dirty: dirty,
		disabled: disabled,
		error: name && methods?.formState?.errors ? methods?.formState?.errors[name] as {
			message?: string;
		} : undefined,
		id,
		name,
		invalid: invalid,
		required: required,
	};

	const [
		state,
		setState,
	] = useState(initialState);

	const hookRef = useRef();
	const localRef = innerRef || hookRef;

	function getErrorMsg() {
		return (t('general.form.input.error.checkbox')) as string;
	}

	useEffect(() => {
		const newState = {
			...state,
			checked: checked,
		};
		setState(newState);
	}, [
		checked
	]);

	function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
		const isInvalid = validationType !== EnumFormMode.ON_SUBMIT && state.required && state.checked ? true : false;
		const isDirty = dirty || validationType !== EnumFormMode.ON_SUBMIT && (state.dirty === false || (state?.value !== state.initialValue) as boolean);

		let newState: InputCheckboxProps = {
			...state,
			error: {
			},
			dirty: isDirty,
			required: required,
			checked: !state.checked,
		};

		if (isInvalid) {
			newState = {
				...newState,
				error: {
					message: getErrorMsg()
				},
			};
		}

		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods && newState.name && methods.clearErrors) {
			methods.clearErrors(newState.name);
		}

		setState(newState);

		if (onChange) onChange(event, newState);
	}

	let errorMsgText = undefined as string | undefined;
	if (typeof state.error === 'object' && state.error?.message) {
		errorMsgText = state.error.message;
	} else if (typeof invalid === 'object' && invalid?.message) {
		errorMsgText = customError ? customError : invalid?.message;
	}

	const classes = [
		styles.input_checkbox,
	];

	if (className) classes.push(className);
	if (disabled) classes.push(styles.disabled);

	const [
		cssClasses,
		setCssClasses
	] = useState(classes);

	useEffect(() => {
		const removeCssClasses = [
			styles.error,
			styles.disabled
		];
		const newCssClasses = cssClasses.filter(cssClass => !removeCssClasses.includes(cssClass));

		if (disabled) newCssClasses.push(styles.disabled);
		if (errorMsgText || invalid) newCssClasses.push(styles.error);

		setCssClasses(newCssClasses);
	}, [
		disabled,
		errorMsgText,
		invalid
	]);

	const handleOnBlur = () => {
		const newCssClasses = cssClasses.filter(cssClass => cssClass !== styles.focused);
		setCssClasses(newCssClasses);
	};

	const handleOnFocus = () => {
		setCssClasses([
			...cssClasses,
			styles.focused
		]);
	};

	const errorMsgElement = errorMsgText ? (
		<div
			className={styles.error_message}
			data-error
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{errorMsgText}
		</div>
	) : null;

	const cssClassesLabel = [
	];

	if (theme === 'toggle') cssClassesLabel.push(styles.input_label);
	if (typeof label === 'object') cssClassesLabel.push(styles.input_checkbox__component);

	const input = (
		<div
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			onBlur={handleOnBlur}
			onClick={(event) => {
				event.stopPropagation();
			}}
			onFocus={disabled ? null : handleOnFocus}
		>
			<Input
				{...otherProps}
				checked={state.checked}
				data-testid={`${dataTestid}-input`}
				id={id}
				innerRef={localRef}
				invalid={state.error || invalid}
				methods={methods}
				name={name}
				registerRules={registerRules}
				type={EnumInputType.CHECKBOX}
				onChange={disabled ? null : handleOnChange}
			/>
			{(
				<LabelInput
					className={cssClassesLabel.join(' ')}
					data-testid={`${dataTestid}-label`}
					id={id}
					invalid={state.error?.message || invalid?.message ? true : false}
					tabIndex={tabIndex}
				>
					{theme === 'toggle' ? (
						<Toggle
							checked={state.checked}
							className={styles.toggle}
							data-testid={`${dataTestid}-toggle`}
							disabled={disabled}
							toggleIcon={toggleIcon}
						/>
					) : (
						<Checkbox
							checked={state.checked}
							className={styles.checkbox}
							data-testid={`${dataTestid}-checkox`}
							disabled={disabled}
						/>
					)}
					{label ? <span className={styles.input_checkbox__text}>{typeof label === 'string' ? decodeURI(t(label)) : label}</span> : null}
				</LabelInput>
			)}

			{state.error?.message || invalid ? errorMsgElement : null}
		</div>
	);

	return input;
};

InputCheckbox.displayName = EnumComponentType.INPUT_CHECKBOX;

export {
	InputCheckbox as default,
	InputCheckboxProps,
};
