import {
	ImageJson,
	Person,
	PersonJson,
	User,
	UserJson,
} from '@@types/index';

export interface SuggestedContactForVisitJson {
	broker: UserJson;
	visited_at: string;
}

export interface SuggestedContactForVisit {
	broker: string;
	visited_at: string;
}

export interface ContactJson extends PersonJson {
	civility_key: string;
	company_name?: string;
	enable: boolean;
	full_name?: string;
	id?: number;
	job?: string;
	logo?: ImageJson;
	suggested_contact_for_visit?: SuggestedContactForVisitJson;
}

export class Contact extends Person {
	public contact: ContactJson;
	public language: string;

	constructor(
		contact: ContactJson,
		language: string
	) {
		super({
			email: contact?.email,
			firstname: contact?.firstname,
			lastname: contact?.lastname,
			mobile: contact?.mobile,
			phone: contact?.phone,
		});
		this.contact = contact;
		this.language = language;
	}

	get civility_key(): string {
		return this.contact?.civility_key || 'mister';
	}

	get company_name(): string | undefined {
		return this.contact?.company_name ? this.contact.company_name : undefined;
	}

	get enable(): boolean {
		return this.contact?.enable || false;
	}

	get id(): number | undefined {
		return this.contact?.id ? this.contact.id : undefined;
	}

	get job(): string | undefined {
		return this.contact?.job ? this.contact.job : undefined;
	}

	get logo(): ImageJson | undefined {
		return this.contact?.logo ? this.contact.logo : undefined;
	}

	get suggested_contact_for_visit(): SuggestedContactForVisit | undefined {
		return this.contact.suggested_contact_for_visit ? {
			broker: new User(this.contact.suggested_contact_for_visit.broker).full_name,
			visited_at: new Date(this.contact.suggested_contact_for_visit.visited_at).toLocaleDateString(this.language),
		} : undefined;
	}
}
