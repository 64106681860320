import {
	MouseEvent,
	useEffect,
	useState,
} from 'react';
import i18n from '@modules/i18n';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	useDispatch,
	useSelector,
} from 'react-redux';
import {
	useParams,
} from 'react-router-dom';

// TYPES
import {
	DemandJsonPrivate,
	DemandJsonPublic,
} from '@@types/Demand';
import {
	Offer,
	OfferJson,
} from '@@types/Offer';

// ENUMS
import {
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// COMPONENTS
import {
} from '@components/button';
import {
	ButtonListProps,
} from '@components/button-list';
import {
	ButtonWithDropdownProps,
} from '@components/button-with-dropdown';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';

// DATA
import {
	getItemsOfferData,
} from '@static_data/button-with-dropdown-offer.data';

// LAYOUTS
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';

// STORES
import {
	ReducerInstance,
} from '@stores/lpdipro/reducers';
import {
	demandUpdate,
} from '@stores/_slices/demands';

// MODULES
import utils from '@modules/utils';

// ROUTES
import FETCHES from '@routes/fetches';

export const GetCardsConfiguration = (data: Offer, isDesktopResolution: boolean, demandId: number, commercialization_step: string, onClickModal?: (event: MouseEvent<HTMLElement>, bodyRequest: BodyRequest) => void, onClickCommercializationStep?: (event: MouseEvent<HTMLElement>, bodyRequest: BodyRequest) => void) => {
	const dispatch = useDispatch();
	const device = (useSelector((state: ReducerInstance) => state.device.instance));
	const brokers = useSelector((state: ReducerInstance) => state.user.instance.company.brokers);
	const documentTemplates = useSelector((state: ReducerInstance) => state.user.instance.company.document_templates);
	const { demandid, token } = useParams();

	const demandWithOffersGlobalState = useSelector((state: ReducerInstance) => state.demands.instance).collection.filter((item: DemandJsonPrivate | DemandJsonPublic) => demandid ? (Number(demandid) == item?.id) : token == item?.shared_demand_token);

	const demandGlobalState = demandWithOffersGlobalState && demandWithOffersGlobalState[0] ? demandWithOffersGlobalState[0] : null;
	const [
		isBookmarked,
		setIsBookmarked
	] = useState(false);
	const [
		isPinned,
		setIsPinned
	] = useState(false);

	useEffect(() => {
		const initialBookmarked = data?.states?.includes('bookmarked') ? true : false;
		const initialpinned = data?.states?.includes('pinned') ? true : false;
		setIsPinned(initialpinned);
		setIsBookmarked(initialBookmarked);
	}, [
		data?.states
	]);

	const getStates = async (bodyRequest: BodyRequest) => {
		const response = await fetch(utils.getURL(FETCHES.private.demands.offers, {
			':demandid': `${demandId}`
		}), {
			...APP_CONF_VARS.request.default,
			method: 'PATCH',
			body: JSON.stringify(bodyRequest),
		}).then((resp) => {
			return resp.json();
		}).catch(err => {
			console.warn('ERROR : ', err);
		});

		return await response;
	};

	const setDemandGlobalState = (states: string[]) => {
		const updatedOffers: OfferJson[] = [
		];
		let offerUpdated = null;

		demandGlobalState.offers.collection.forEach((offer: OfferJson) => {
			if (offer.id === data.id) {
				offerUpdated = {
					...offer,
					states: states
				};
				updatedOffers.push(offerUpdated);
			} else {
				updatedOffers.push(offer);
			}
		});
		dispatch(demandUpdate({
			demand: {
				...demandGlobalState,
				offers: {
					...demandGlobalState.offers,
					collection: [
						...updatedOffers,
					]
				}
			},
			demand_id: demandId,
		}));
	};

	const handleOnClickThumbtack = () => {

		let stateUpdate: string[] = [
		];

		if (data?.states?.length) stateUpdate.push(...data.states);

		if (isPinned) {
			stateUpdate = data.states.filter(state => state !== 'pinned');
		} else {
			stateUpdate.push('pinned');
		}

		const newBodyRequest = {
			ids: [
				data.id
			],
			commercialization_step,
			states: stateUpdate
		};
		const fetchResponse = getStates(newBodyRequest);
		fetchResponse.then((response) => {
			switch (response?.status) {
				case 200:
					setIsPinned(!isPinned);
					setDemandGlobalState(stateUpdate);
					break;
			}
		});
	};

	const handleOnClickStar = () => {

		let stateUpdate: string[] = [
		];

		if (data?.states?.length) stateUpdate.push(...data.states);

		if (isBookmarked) {
			stateUpdate = data.states.filter(state => state !== 'bookmarked');
		} else {
			stateUpdate.unshift('bookmarked');
		}

		const newBodyRequest = {
			ids: [
				data.id
			],
			commercialization_step,
			states: stateUpdate
		};
		const fetchResponse = getStates(newBodyRequest);
		fetchResponse.then((response) => {
			switch (response?.status) {
				case 200:
					setIsBookmarked(!isBookmarked);
					setDemandGlobalState(stateUpdate);
					break;
			}
		});
	};

	const elementsDesktop: ButtonListProps['items'] = [
		{
			iconLeft: 'star',
			iconStyle: isBookmarked ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isBookmarked ? i18n.t('general.remove_bookmark') : i18n.t('general.bookmark_customer'),
			}),
			name: 'bookmark',
			theme: EnumTheme.NAKED,
			onClick: handleOnClickStar
		},
		{
			hasBoxShadow: false,
			dropdownTheme: 'secondary',
			iconLeft: 'ellipsis-stroke',
			name: `more_actions_${data.id}`,
			items: getItemsOfferData(brokers, device, documentTemplates, data.id, onClickModal),
			style: {
				bottom: isDesktopResolution ? '-70%' : null,
				left: isDesktopResolution ? '100%' : '0%',
				top: isDesktopResolution ? null : '150%',
			},
			theme: EnumTheme.NAKED,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.more_actions')
			}),
		} as ButtonWithDropdownProps,
		{
			iconLeft: 'thumbtack',
			iconStyle: isPinned ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isPinned ? i18n.t('general.unpin') : i18n.t('general.pin'),
			}),
			name: 'pin',
			size: EnumButtonSize.SMALL,
			theme: EnumTheme.NAKED,
			onClick: handleOnClickThumbtack
		},
	];

	const elementsMobile: DropdownItemProps[] = [
		{
			iconLeft: 'star',
			iconStyle: isBookmarked ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isBookmarked ? i18n.t('general.remove_bookmark') : i18n.t('general.bookmark_customer'),
			}),
			onClick: handleOnClickStar
		},
		...getItemsOfferData(brokers, device, documentTemplates, data.id, onClickModal) as DropdownItemProps[],
		{
			iconLeft: 'thumbtack',
			iconStyle: isPinned ? EnumFontStyle.SOLID : EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: isPinned ? i18n.t('general.unpin') : i18n.t('general.pin'),
			}),
			onClick: handleOnClickThumbtack
		},
	];

	if (commercialization_step === 'proposed') {
		elementsMobile.unshift({
			iconLeft: 'trash-alt',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.ignored'),
			}),
			id: 'ignored',
			separator: true,
			onClick: (event) => {
				onClickCommercializationStep(event, {
					ids: [
						data.id
					],
					commercialization_step: 'ignored',
					states: data.states
				});
			},
		});
		elementsMobile.unshift({
			iconLeft: 'calendar-plus',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.plan_my_visit'),
			}),
			id: 'plan_my_visit',
			onClick: (event) => {
				onClickModal(event, {
					ids: [
						data.id
					],
					address: data.building?.address?.full,
					name: 'plan_my_visit',
					contacts: data.contacts,
					images: data?.images?.collection[0]?.url,
					demandId: demandGlobalState.id,
					states: data.states,
					visit: data.visit
				});
			}
		});
	}
	if (commercialization_step === 'applied') {
		elementsMobile.unshift({
			iconLeft: 'arrow-back',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.cancel_apply'),
			}),
			id: 'proposed',
			separator: true,
			onClick: (event) => {
				onClickModal(event, {
					ids: [
						data.id
					],
					address: data.building?.address?.full,
					name: 'proposed',
					contacts: data.contacts,
					images: data?.images?.collection[0]?.url,
					demandId: demandGlobalState.id,
					states: data.states,
					visit: data.visit
				});
			}
		});
		elementsMobile.unshift({
			iconLeft: 'calendar-plus',
			iconStyle: EnumFontStyle.LIGHT,
			label: i18n.t('format.capitalize', {
				text: i18n.t('general.action.plan_my_visit'),
			}),
			id: 'plan_my_visit',
			onClick: (event) => {
				onClickModal(event, {
					ids: [
						data.id
					],
					address: data.building?.address?.full,
					name: 'plan_my_visit',
					contacts: data.contacts,
					images: data?.images?.collection[0]?.url,
					demandId: demandGlobalState.id,
					states: data.states,
					visit: data.visit
				});
			}
		});
	}

	return isDesktopResolution ? elementsDesktop : elementsMobile;
};
