// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EIDhRKH3{font-size:1rem;display:flex;flex-direction:column;margin:0;position:relative}.EIDhRKH3 label{cursor:pointer;display:flex;flex-direction:row}.EIDhRKH3 label .KDwJafK5+.SLKfvozc{margin-top:0.21428571rem}.EIDhRKH3 input{display:none}.YzJx3MbG{align-items:center;overflow:hidden}.SLKfvozc{display:flex;flex-direction:row;margin-left:0.92857143rem}.lhj1NHop{color:var(--color-status-error);font-size:0.85714286rem;position:absolute;text-align:left;top:100%}.lhj1NHop input,.lhj1NHop i{color:var(--color-status-error)}.d9tOdRWl i{border-color:var(--color-status-error)}.d9tOdRWl i:hover{border-color:var(--color-status-error)}.jAy3hbkr label{background:var(--color-wild-sand);background-color:inherit;border-radius:0.57142857rem;color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5);cursor:not-allowed}.jAy3hbkr label i{color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}.jAy3hbkr label i:hover{background-color:var(--color-white);border-color:hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}.ApisQhmV{color:currentcolor}.ApisQhmV .aSzQAsZq{border-color:var(--color-border-focus)}.ApisQhmV .KDwJafK5{border-color:var(--color-border-focus)}.o0Wzl3xk{align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input_checkbox": `EIDhRKH3`,
	"toggle": `KDwJafK5`,
	"input_checkbox__text": `SLKfvozc`,
	"input_checkbox__component": `YzJx3MbG`,
	"error_message": `lhj1NHop`,
	"error": `d9tOdRWl`,
	"disabled": `jAy3hbkr`,
	"focused": `ApisQhmV`,
	"checkbox": `aSzQAsZq`,
	"input_label": `o0Wzl3xk`
};
export default ___CSS_LOADER_EXPORT___;
