import * as React from 'react';
import {
	createElement,
	MouseEvent,
	ReactElement,
} from 'react';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';

import {
	EnumTargetLink,
	EnumThemeLink,
} from '@enums/link.enum';

//COMPONENT
import Checkbox from '@components/checkbox';
import Icon from '@components/icon';
import Link from '@components/link';

//STYLES
import styles from './dropdown-item.module.scss';

interface DropdownItemProps {
	'data-testid'?: string;
	className?: string;
	content?: ReactElement;
	disabled?: boolean;
	enabled?: boolean;
	fontWeight?: EnumFontStyle;
	hasAccess?: boolean;
	hasEllipsis?: boolean;
	href?: string;
	iconLeft?: string;
	iconRight?: string;
	iconStyle?: EnumFontStyle.LIGHT | EnumFontStyle.REGULAR | EnumFontStyle.SOLID;
	id?: string;
	isDisplayed?: boolean;
	index?: number;
	itemData?: DropdownItemProps;
	label?: string;
	labelEnabled?: string;
	onClick?: (event: MouseEvent<HTMLElement>, props: object) => void;
	selected?: boolean;
	separator?: boolean;
	target?: EnumTargetLink;
	theme?: EnumTheme | EnumStatusTheme;
	to?: string;
	value?: string;
	withCheckbox?: boolean;
}

const DropdownItem = ({
	'data-testid': dataTestid,
	className,
	content,
	disabled = false,
	enabled = false,
	fontWeight = EnumFontStyle.REGULAR,
	hasAccess = true,
	hasEllipsis,
	href,
	iconLeft,
	iconRight,
	iconStyle,
	id,
	isDisplayed = true,
	itemData,
	index,
	label,
	labelEnabled,
	onClick,
	selected,
	separator,
	target,
	theme,
	to,
	value,
	withCheckbox,
}: DropdownItemProps): JSX.Element => {
	let line = null;
	const handleOnClick = (event: MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		onClick && itemData ? onClick(event, itemData) : undefined;
		if (content?.props?.onClick) content.props.onClick(event);
	};
	const classes = [
		styles.dropdown_item,
	];

	if (className) classes.push(className);
	if (!hasAccess || disabled) classes.push(styles.disabled);
	if (selected) classes.push(styles.active);
	classes.push(styles[`${'theme__' + theme}`]);

	const lineClassNames = [
		hasEllipsis ? styles.dropdown_item__line_ellipsis : styles.dropdown_item__line,
	];

	if (withCheckbox) classes.push(styles.multi_select);
	if (separator) classes.push(styles.separator);

	let icon = null;
	if (iconLeft) {
		icon = (
			<Icon
				className={styles.icon_left}
				fontStyle={selected ? EnumFontStyle.SOLID : iconStyle}
				name={iconLeft}
			/>
		);
	}

	if (iconRight) {
		icon = (
			<Icon
				className={styles.icon_right}
				fontStyle={selected ? EnumFontStyle.SOLID : iconStyle}
				name={iconRight}
			/>
		);
	}

	if (content?.type) {
		const element = createElement(content.type, {
			...content.props,
			onClick: handleOnClick
		});
		line = (
			<span
				className={lineClassNames.join(' ')}
				data-index={index}
				data-select-value={value}
				data-testid={`${dataTestid}-content`}
				onClick={handleOnClick}
			>
				{ withCheckbox ? (
					<>
						<Checkbox
							checked={selected}
							className={styles.checkbox}
							data-testid={`${dataTestid}-checkbox`}
						/>
						<span>{element}</span>
					</>
				) : element}
			</span>
		);
	} else if (to || href) {
		let labelElement =  label;

		if (enabled && labelEnabled) {
			labelElement = labelEnabled;
		}

		line = (
			<Link
				className={lineClassNames.join(' ')}
				data-testid={`${dataTestid}-link`}
				fontWeight={fontWeight}
				hasAccess={hasAccess}
				href={href}
				target={target}
				theme={EnumThemeLink.NAKED}
				onClick={handleOnClick}
			>
				{iconLeft ? (
					<Icon
						className={styles.icon_left}
						fontStyle={selected ? EnumFontStyle.SOLID : iconStyle}
						name={iconLeft}
					/>
				) : null}
				{labelElement}
				{iconRight ? (
					<Icon
						className={styles.icon_right}
						fontStyle={selected ? EnumFontStyle.SOLID : iconStyle}
						name={iconRight}
					/>
				) : null}
			</Link>
		);
	} else {
		line = (
			<span
				className={lineClassNames.join(' ')}
				data-index={index}
				data-select-value={value}
				onClick={handleOnClick}
			>
				{withCheckbox ? (
					<>
						<Checkbox
							checked={selected}
							className={styles.checkbox}
							data-testid={`${dataTestid}-checkbox`}
						/>
						{iconLeft ? icon : null}
						<span>{label}</span>
						{iconRight ? icon : null}
					</>
				) : iconLeft || iconRight ? (
					<>
						{iconLeft ? icon : null}
						<span>{label}</span>
						{iconRight ? icon : null}
					</>
				) : label}
			</span>
		);
	}
	const itemElement = isDisplayed ? (
		<li
			className={classes.join(' ')}
			data-testid={dataTestid}
			id={id}
		>
			{line}
		</li>
	) : undefined;

	return itemElement;
};

export {
	DropdownItem as default,
	DropdownItemProps,
};
