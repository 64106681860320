import * as React from 'react';

import {
	Trans,
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';

// COMPONENTS
import Icon from '@components/icon';
import Image, {
	ImageProps,
} from '@components/image';
import Link from '@components/link';
import Text from '@components/text';
import Tooltip from '@components/tooltip';

// TYPES
import {
	SuggestedContactForVisit,
} from '@@types/Contact';

// STATIC DATA
import {
	userCardThemeConfs,
} from './UserCard.themes.conf';

// STYLING
import styles from './UserCard.module.scss';

interface descriptionProps {
	type?: string;
	value?: string;
	url?: string;
	icon?: string;
	required?: boolean;
}

interface UserDataProps {
	descriptions?: descriptionProps[];
	id?: string;
	image_url?: string;
	isHiddenBubble?: boolean;
	placeholder?: string;
	title?: string;
}

export enum ThemeUserCard {
	DEFAULT = 'default',
	SQUARE = 'square',
	ROUND = 'round',
}

interface UserCardProps {
	className?: string;
	data?: UserDataProps;
	'data-testid'?: string;
	hasBigTitle?: boolean;
	hasEllipsis?: boolean;
	theme?: ThemeUserCard;
	tight?: boolean;
	visitData?: SuggestedContactForVisit;
}

function UserCard({
	className,
	data,
	'data-testid': dataTestid,
	hasBigTitle = false,
	hasEllipsis = false,
	theme = ThemeUserCard.DEFAULT,
	tight = false,
	visitData,
}: UserCardProps) {
	const { t } = useTranslation();
	const classNames = [
		styles.UserCard
	];
	if (className) classNames.push(className);

	const cssInfosClasses = [
		styles.infos,
	];
	(data?.isHiddenBubble) ? null : cssInfosClasses.push(styles.bubble);

	if (tight) classNames.push(styles.tight);

	if (hasBigTitle) classNames.push(styles.bigTitle);

	const themeDefinedConf = (userCardThemeConfs.filter(themeDefinedConf => themeDefinedConf.name === theme)[0] as ImageProps);
	classNames.push(themeDefinedConf.className);

	const imageElement = data?.image_url ? (
		<div
			className={styles.image}
			data-testid={`${dataTestid}-image`}
		>
			<Image
				format={themeDefinedConf.format}
				imagePlaceholder={themeDefinedConf.imagePlaceholder}
				rounded={themeDefinedConf.rounded}
				src={data.image_url}
			/>
		</div>
	) : null;

	let initial = data?.title?.charAt(0) || '?';

	data?.descriptions?.forEach((item: descriptionProps) => {
		if (item.type === 'subtitle' && item?.value) {
			initial = item.value.charAt(0);
		}
	});

	const initialElement = (
		<div
			className={styles.initial}
			data-testid={`${dataTestid}-initial`}
		>
			{
				t('format.uppercase', {
					text: initial
				})
			}
		</div>
	);

	const notSpecified = t('format.capitalize', {
		text: t('general.not_specified')
	});

	const titleElement = (
		<Text
			className={styles.title}
			data-testid={`${dataTestid}-title`}
			ellipsis={hasEllipsis}
		>
			{data?.title ? data.title : notSpecified}
		</Text>
	);

	const getDescriptionValue = (description: descriptionProps) => {
		let result = null;
		if (description.value) {
			result = description.value;
		} else {
			result = notSpecified;
		}
		return result;
	};

	const descriptionsElement = (
		<div data-testid={`${dataTestid}-descriptions`}>
			{data?.descriptions?.map((description: descriptionProps, key: number) => {
				let resultElement = null;
				let telFormated = undefined;
				switch (description.type) {
					case 'tel': {
						description.value ? telFormated = t('format.tel', {
							'val': description.value,
						}) : telFormated = description.required ? notSpecified: undefined;
						resultElement = (description.value || description.required) ? (
							<div
								className={styles.link}
								key={key}
							>
								<Link
									fontWeight={EnumFontStyle.REGULAR}
									href={`tel:${description.value}`}
									target={EnumTargetLink.BLANK}
								>
									<Icon
										className={styles.icon}
										fontStyle={EnumFontStyle.LIGHT}
										name='phone-alt'
									/>
									{telFormated}
								</Link>
							</div >
						) : null;
						break;
					}
					case 'email': {
						resultElement = (description?.required || description?.value) ? (
							<div
								className={styles.link}
								key={key}
							>
								<Link
									fontWeight={EnumFontStyle.REGULAR}
									href={`mailto:${description.value}`}
									target={EnumTargetLink.BLANK}
								>
									<Icon
										className={styles.icon}
										fontStyle={EnumFontStyle.LIGHT}
										name='envelope'
									/>
									{getDescriptionValue(description)}
								</Link>
							</div>
						) : null;
						break;
					}
					case 'link': {
						resultElement = (description?.required || description?.value) ? (
							<div
								className={styles.link}
								key={key}
							>
								<Link
									fontWeight={EnumFontStyle.REGULAR}
									href={description.url}
									target={EnumTargetLink.BLANK}
								>
									<Icon
										className={styles.icon}
										fontStyle={EnumFontStyle.LIGHT}
										name={description.icon}
									/>
									{getDescriptionValue(description)}
								</Link>
							</div>
						) : null;
						break;
					}

					case 'subtitle':
					default: {
						resultElement = (description?.required || description?.value) ? (
							<div
								className={styles.description}
								key={key}
							>
								<Trans
									i18nKey={getDescriptionValue(description)}
								/>
							</div>
						) : null;
					}
				}
				return resultElement;
			})}
		</div >
	);

	const visitContent = visitData ? t('format.capitalize', {
		text: t('component.user_card.tooltip_visit', {
			'contact': visitData?.broker,
			'date': visitData?.visited_at,
		})
	}) as string : null;

	return (
		<div
			className={classNames.join(' ')}
			data-testid={dataTestid}
		>

			<div className={styles.left}>
				{data?.isHiddenBubble ? null : imageElement || initialElement}
				{visitData && visitContent ? (
					<Tooltip
						content={visitContent}
						id='visit'
					>
						<div
							className={styles.visit}
							data-testid={`${dataTestid}-visit`}
						>
							<Icon
								className={styles.icon}
								fontStyle={EnumFontStyle.SOLID}
								name='walking'
							/>
						</div>
					</Tooltip>
				) : null }
			</div>
			<div className={cssInfosClasses.join(' ')}>
				{titleElement}
				{data?.descriptions ? descriptionsElement : null}
			</div>
		</div>
	);
}

export {
	UserCard as default,
	UserCardProps,
	UserDataProps,
};

