export enum EnumComponentType {
    BUTTON = 'Button',
    INPUT = 'Input',
    INPUT_CHECKBOX = 'InputCheckbox',
    INPUT_DATE_PICKER = 'InputDatePicker',
    INPUT_HIDDEN = 'InputHidden',
    INPUT_LABEL = 'InputLabel',
    INPUT_PASSWORD = 'InputPassword',
    INPUT_PASSWORD_WITH_VALIDATION = 'InputPasswordWithValidation',
    INPUT_RADIO_GROUP = 'InputRadioGroup',
    INPUT_SEARCH = 'InputSearch',
    INPUT_SEARCH_WITH_DROPDOWN = 'InputSearchWithDropdown',
    INPUT_SELECT = 'InputSelect',
    INPUT_TEXT = 'InputText',
    INPUT_TEXT_WITH_UNIT = 'InputTextWithUnit',
    INPUT_TIME_PICKER = 'InputTimePicker',
    LINK = 'Link',
    TEXTAREA = 'Textarea',
}
