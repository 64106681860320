import {
	datadogLogs,
} from '@datadog/browser-logs';
import {
	datadogRum,
} from '@datadog/browser-rum';
import {
	APP_CONF_VARS,
} from '@appConf/vars.conf';
import {
	UserJson,
} from '@@types/User';
import * as Sentry from '@sentry/react';
import utils from '@modules/utils';

interface argsProps {
	messageContext?: string;
	status?: string | Error;
}

interface LoggerProps {
	init: () => void;
	initDataDog: () => void;
	initSentry: () => void;
	setUser: (user: UserJson) => void;
	canLog: () => boolean;
	log: (msg: string, args: argsProps) => void;
}

class Logger {
	env?: string;
	datadogSettings?: {
		service?: string;
		logs?: {
			clientToken?: string;
		};
		site?: string;
		rum?: {
			applicationId?: string;
			clientToken?: string;
		};
	};
	initialized?: boolean;
	constructor() {
		// Var to check if env is prod
		this.env = utils.getEnvFromhost();
		this.datadogSettings = APP_CONF_VARS.tools.datadog;
		this.initialized = false;
	}

	init() {
		if (this.initialized) return;
		/* istanbul ignore next */
		if (this.canLog()) {
			this.initDataDog();
		}
		this.initSentry();
		this.initialized = true;
	}

	/* istanbul ignore next */
	initDataDog() {
		datadogLogs.init({
			clientToken: this.datadogSettings.logs.clientToken,
			env: this.env,
			forwardErrorsToLogs: true,
			sampleRate: 100,
			service: this.datadogSettings.service,
			site: this.datadogSettings.site,
			silentMultipleInit: true,
			version: process.env.npm_package_version
		});
		datadogRum.init({
			applicationId: this.datadogSettings.rum.applicationId,
			clientToken: this.datadogSettings.rum.clientToken,
			env: this.env,
			sampleRate: 100,
			service: this.datadogSettings.service,
			site: this.datadogSettings.site,
			trackInteractions: true,
			silentMultipleInit: true,
			version: process.env.npm_package_version
		});
		window.hbsDatadog = {
			datadogLogs,
			datadogRum
		};
	}

	initSentry() {
		/* istanbul ignore next */
		if (!process.env.SENTRY_DSN) return;
		/* istanbul ignore next */
		Sentry.init({
			dsn: process.env.SENTRY_DSN,

			// Alternatively, use `process.env.npm_package_version` for a dynamic release version
			// if your build tool supports it.
			release: process.env.npm_package_version || '1.0.0',
			integrations: [
				Sentry.browserTracingIntegration(),
				Sentry.replayIntegration(),
				Sentry.browserProfilingIntegration(),
			],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for tracing.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,

			// Set profilesSampleRate to 1.0 to profile every transaction.
			// Since profilesSampleRate is relative to tracesSampleRate,
			// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
			// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
			// results in 25% of transactions being profiled (0.5*0.5=0.25)
			profilesSampleRate: 1.0,

			// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
			// tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

			// Capture Replay for 10% of all sessions,
			// plus for 100% of sessions with an error
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			debug: false,
			environment: this.env,
		});
	}

	/* istanbul ignore next */
	setUser(user: UserJson) {
		/* istanbul ignore next */
		if (process.env.SENTRY_DSN) {
			Sentry.setUser({
				id: user.id || 0,
				email: user.email || undefined,
				username: user.username || undefined
			});
		}
	}

	canLog() {
		return [
			'production',
			'staging',
		].includes(this.env);
	}

	log(msg: string, args: argsProps = {
	}) {
		if (!this.initialized || !msg) return;
		const argsOptions = {
			messageContext: args?.messageContext || {
			},
			status: args?.status || 'info',
			msg: msg,
			...args,
		};
		/* istanbul ignore next */
		if (this.canLog()) {
			datadogLogs.logger.info(msg, argsOptions.messageContext, argsOptions.status as Error);
		} else {
			console.log('LOGGER - MSG :', argsOptions);
		}
	}
}

const initializedLogger = new Logger();
initializedLogger.init();

export {
	initializedLogger,
	Logger as default,
	LoggerProps,
};
